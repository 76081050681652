import React from "react"
import { IMG } from "../const"

export const TITLES = {
  testimonials: {
    title: "clients’ testimonials",
    strongText: (
      <>
        our clients often come to us through recommendations, which is a good
        indicator of our services
      </>
    ),
    uppercase: (
      <>
        Trusted
        <br /> partnership
      </>
    ),
    withIndent: false,
  },
  solutions: {
    title: (
      <>
        <strong>telecom</strong> software solutions that we provide:
      </>
    ),
    strongText: "We offer a wide range of telecom software services",
    uppercase: "provide answers",
    withIndent: false,
  },
  tech_stack: {
    title: (
      <>
        our tech <strong>stack</strong>
      </>
    ),
    strongText: "modern technology for telecommunication tech industry",
    uppercase: (
      <>
        telecom tech
        <br /> stack we use
      </>
    ),
    withIndent: false,
  },
  case_studies: {
    title: (
      <>
        case studies we're <strong>proud of</strong>
      </>
    ),
    strongText:
      "We use a structured and tested approach to provide beautiful digital services for our partners",
    uppercase: (
      <>
        cases from
        <br /> 10+ industries
      </>
    ),
    withIndent: true,
  },
  technology: {
    title: (
      <>
        LaSoft <br />
        <strong>one–stop shop</strong> <br />
        technology partner:
      </>
    ),
    strongText:
      "working with us our clients receive a full range of technology services",
    uppercase: "full range of services",
    withIndent: true,
  },
  colored: {
    title: (
      <>
        interested in
        <br /> our dedicated telecom
        <br /> team?
      </>
    ),
    strongText:
      "request a consultation so that we can help build your telecom dedicated team today",
    uppercase: <>Book&nbsp;a&nbsp;consultation&nbsp;today</>,
    link: "#contact-form",
    linkClassName: "telecom-book",
    imageDesktop: `${IMG.TELECOM_SOFTWARE}/scheme-white.svg`,
    imageDesktopWidth: 730,
    imageDesktopHeight: 92,
    withIndent: false,
    industry: "telecommunication",
  },
}

export const TELECOM_DEVELOPMENT_SERVICES = [
  {
    id: 1,
    title: "Full circle software development",
    text:
      "We can plan, design, and develop your product as a solo vendor, and we do it.  We take care of all aspects of software development, including product design and architecture, UI/UX design, Web App development, Quality Assurance, project management, and project support.",
  },
  {
    id: 2,
    title: "World-class product design expertise",
    text:
      "To create a new design or buy a template? Many of our customers have this dilemma when they decide to build a webpage. This option is suitable for testing your ideas.",
  },
  {
    id: 3,
    title: "Cloud Infrastructure deployment and support",
    text:
      "We can create or develop cloud infrastructure for business. Want to remove your business from the cloud? We’ll take care of it. Want to optimize your finances in DevOps? We will find the cheapest solution without losing the quality of your  business!",
  },
  {
    id: 4,
    title: "Expertise in data analytics and custom dashboard development",
    text:
      "We have extensive knowledge in developing high-load dashboards with modern infographics. Our teammates worked in world-famous companies like HP, Pearson, Adidas, Boss, Calvin Klein, and s.Oliver.",
  },
  {
    id: 5,
    title: "Mobile development",
    text:
      "Android or iOS? You don’t have to choose. We make solutions for all operating systems.",
  },
]

export const SERVICES = {
  items: [
    {
      id: 1,
      title: "Dedicated Teams for Telecom",
      text: (
        <>
          <p>
            The development of digital telecommunications solutions usually
            takes months. Strengthen your technical team with our highly
            dedicated teams or individual dedicated employees. We use a
            structured and tested approach to build a professional, long-term
            dedicated team for our partners.
          </p>
          <p>
            Our project managers fully cover the administrative part of the
            telecom outsourcing service. This includes one-on-one meetings,
            timesheets, invoices, coordinating vacation days, reviewing/raising
            salaries, processing applications, and hiring new employees. So you
            can focus only on the essential workflow!
          </p>
        </>
      ),
    },
    {
      id: 2,
      title: (
        <>
          Business Support System <br />
          (BSS) Development
        </>
      ),
      text: (
        <>
          <p>
            Simplify your workflows, monitor service quality and resolve
            customer issues quickly with LaSoft. We offer BSS software telecom
            solutions we provide:
          </p>
          <ul>
            <li>Web development;</li>
            <li>Telecom application development;</li>
            <li>Custom business automation solutions;</li>
            <li>Telecom billing software;</li>
            <li>CRM development and integrations.</li>
          </ul>
        </>
      ),
    },
    {
      id: 3,
      title: (
        <>
          Operations Support System <br />
          (OSS) Development
        </>
      ),
      text: (
        <>
          <p>
            We help our telecom clients to monitor, analyze, and manage telecom
            networks with the most emerging telecom software solutions in the
            operation support systems field. OSS software telecom solutions we
            provide:
          </p>
          <ul>
            <li>Custom performance monitoring and management software;</li>
            <li>Call accounting software;</li>
            <li>Telecom expense management software;</li>
            <li>Security monitoring;</li>
            <li>Network resources inventory management solutions.</li>
          </ul>
        </>
      ),
    },
    {
      id: 4,
      title: "Telecom Data Visualization",
      text: (
        <>
          <p>
            Get data analysis software with beautiful visualization dashboards
            that help make right business decisions.
          </p>
          <ul>
            <li>Telecommunications data management system;</li>
            <li>Data analytics & reports;</li>
            <li>Product and Customer business analytics;</li>
            <li>CRM analytics to optimize the sales funnel.</li>
          </ul>
        </>
      ),
    },
  ],
}

export const DEV_TECHNOLOGIES = [
  { name: "ReactJS", icon: "react.svg" },
  { name: "Angular", icon: "angular.svg" },
  { name: "Laravel", icon: "laravel.svg" },
  { name: "Django", icon: "django.svg" },
  { name: "React Native", icon: "react-native.svg" },
  { name: "Python Flask", icon: "flask-icon.svg" },
  { name: "Ruby on Rails", icon: "ruby-on-rails.svg" },
  { name: "Java", icon: "java.svg" },
]
