// extracted by mini-css-extract-plugin
export var accordionBlock = "telecom-software-development-module--accordionBlock--a34ab";
export var accordionWrapper = "telecom-software-development-module--accordionWrapper--8163b";
export var billetShadow = "telecom-software-development-module--billet-shadow--4252f";
export var colorScheme__background__grayPale = "telecom-software-development-module--colorScheme__background__gray-pale--59fe7";
export var headline = "telecom-software-development-module--headline--c952a";
export var headline_grid = "telecom-software-development-module--headline_grid--d8ecc";
export var icomoon = "telecom-software-development-module--icomoon--ab419";
export var lowercase = "telecom-software-development-module--lowercase--bd221";
export var positionedText = "telecom-software-development-module--positionedText--b411c";
export var solutions = "telecom-software-development-module--solutions--92e19";
export var techStack = "telecom-software-development-module--techStack--a3028";
export var telecomBlock = "telecom-software-development-module--telecomBlock--a26b8";
export var transition = "telecom-software-development-module--transition--3b4e2";
export var transitionBackground = "telecom-software-development-module--transition-background--0484e";
export var transitionReverse = "telecom-software-development-module--transition-reverse--2f8d8";
export var uppercase = "telecom-software-development-module--uppercase--96421";